
  import { Component, Vue } from "vue-property-decorator";
  import {
    IUserProfileAdminUpdate,
    UserRole,
    UserStatus,
    SearchableField,
    IUserProfileCardUpdate,
    IDirectoryEntry,
  } from "@/interfaces";
  import {
    dispatchUpdateUser,
    dispatchAdminUpdateUserProfileCard,
  } from "@/store/admin/actions";
  import { readAdminOneUser } from "@/store/admin/getters";
  import { required, confirmed, email } from "vee-validate/dist/rules";
  import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
  import countries from "@/countries";
  import DirectoryEntry from "@/components/DirectoryEntry.vue";

  // register validation rules
  extend("required", { ...required, message: "{_field_} can not be empty" });
  extend("confirmed", { ...confirmed, message: "Passwords do not match" });
  extend("email", { ...email, message: "Invalid email address" });

  @Component({
    components: {
      ValidationObserver,
      ValidationProvider,
      DirectoryEntry,
    },
  })
  export default class EditUser extends Vue {
    $refs!: {
      observer: InstanceType<typeof ValidationObserver>;
    };

    countries = countries;
    roles = Object.keys(UserRole);
    statuses = Object.keys(UserStatus);

    public valid = true;
    public firstName = "";
    public lastName = "";
    public email = "";
    public phone = "";
    public placeOfEmployment = "";
    public town = "";
    public province = "";
    public country = "";
    public status = UserStatus.active;
    public role = UserRole.user;
    public setPassword = false;
    public password1 = "";
    public password2 = "";
    public isSearchable = false;
    public companySearchable = false;
    public phoneSearchable = false;
    public emailSearchable = false;
    public locationSearchable = false;

    get user() {
      return readAdminOneUser(this.$store)(+this.$router.currentRoute.params.id);
    }

    get directoryEntry(): IDirectoryEntry {
      if (this.user) {
        return {
          user_id: this.user.id,
          name: this.formatName(this.user.first_name, this.user.last_name),
          email: this.emailSearchable ? this.user.email : undefined,
          phone: this.phoneSearchable ? this.user.phone : undefined,
          place_of_employment: this.companySearchable
            ? this.user.place_of_employment
            : undefined,
          address: this.locationSearchable
            ? this.formatAddress(this.user.country, this.user.province, this.user.town)
            : undefined,
          avatar_url: this.user.avatar_url,
        };
      } else {
        return {};
      }
    }

    formatName(firstName?: string, lastName?: string): string {
      if (!firstName && !lastName) {
        return "-----";
      } else {
        return `${firstName || ""} ${lastName || ""}`;
      }
    }

    formatAddress(country?: string, province?: string, town?: string): string {
      var address = "";
      if (town) {
        address += `${town}, `;
      }
      if (province) {
        address += `${province}, `;
      }
      if (country) {
        address += country;
      }
      return address;
    }

    public async mounted() {
      this.onReset();
      this.onResetProfileCard();
    }

    public onReset() {
      this.setPassword = false;
      this.password1 = "";
      this.password2 = "";
      this.$refs.observer.reset();
      if (this.user) {
        this.firstName = this.user.first_name;
        this.lastName = this.user.last_name;
        this.email = this.user.email;
        this.phone = this.user.phone;
        this.town = this.user.town;
        this.province = this.user.province;
        this.country = this.user.country;
        this.placeOfEmployment = this.user.place_of_employment;
        this.status = this.user.status;
        this.role = this.user.role;
      }
    }

    public cancel() {
      this.$router.back();
    }

    public async onSubmit() {
      const success = await this.$refs.observer.validate();
      if (!success) {
        return;
      }
      if (!this.user) {
        return;
      }

      const updatedProfile: IUserProfileAdminUpdate = {};
      if (this.firstName) {
        updatedProfile.first_name = this.firstName;
      }
      if (this.lastName) {
        updatedProfile.last_name = this.lastName;
      }
      if (this.email) {
        updatedProfile.email = this.email;
      }
      if (this.phone) {
        updatedProfile.phone = this.phone;
      }
      if (this.town) {
        updatedProfile.town = this.town;
      }
      if (this.province) {
        updatedProfile.province = this.province;
      }
      if (this.country) {
        updatedProfile.country = this.country;
      }
      if (this.placeOfEmployment) {
        updatedProfile.place_of_employment = this.placeOfEmployment;
      }
      updatedProfile.role = this.role;
      updatedProfile.status = this.status;
      if (this.setPassword) {
        updatedProfile.password = this.password1;
      }
      await dispatchUpdateUser(this.$store, {
        id: this.user.id,
        user: updatedProfile,
      });
      this.$router.push("/main/admin/users");
    }

    public onResetProfileCard() {
      if (this.user) {
        this.isSearchable = this.user.is_searchable;
        const searchFields = this.user.search_fields || [];
        this.companySearchable = searchFields.includes(
          SearchableField.place_of_employment,
        );
        this.phoneSearchable = searchFields.includes(SearchableField.phone);
        this.emailSearchable = searchFields.includes(SearchableField.email);
        this.locationSearchable = searchFields.includes(SearchableField.address);
      }
    }

    public async onSubmitProfileCardUpdate() {
      if (!this.user) {
        return;
      }

      const updatedProfile: IUserProfileCardUpdate = {};
      const fields: SearchableField[] = [SearchableField.name];
      if (this.companySearchable) {
        fields.push(SearchableField.place_of_employment);
      }
      if (this.phoneSearchable) {
        fields.push(SearchableField.phone);
      }
      if (this.emailSearchable) {
        fields.push(SearchableField.email);
      }
      if (this.locationSearchable) {
        fields.push(SearchableField.address);
      }
      updatedProfile.search_fields = fields;
      updatedProfile.is_searchable = this.isSearchable;
      await dispatchAdminUpdateUserProfileCard(this.$store, {
        id: this.user.id,
        user: updatedProfile,
      });
    }
  }
